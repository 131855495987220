body {
  background-color: transparent;
}

.EmbedFrame {
  background-color: var(--mb-color-bg-dashboard);
}

.EmbedFrameHeader,
.EmbedFrameFooter {
  color: var(--mb-color-text-dark);
  background-color: var(--mb-color-bg-dashboard);
}

.ThemeNight.EmbedFrame {
  background-color: var(--mb-color-bg-black);
  border-color: var(--mb-color-bg-dark);
}

.ThemeNight .EmbedFrameHeader,
.ThemeNight .EmbedFrameFooter {
  color: var(--mb-color-text-white-alpha-85);
  background-color: var(--mb-color-bg-black);
  border-color: var(--mb-color-bg-dark);
}

.ThemeNight.EmbedFrame .fullscreenNightText {
  color: var(--mb-color-text-white-alpha-85);
  transition: color 1s linear;
}

.ThemeNight.EmbedFrame svg text {
  fill: var(--mb-color-text-white-alpha-85) !important;
  stroke: none !important;
}

.ThemeNight.EmbedFrame .DashCard .Card {
  background-color: var(--mb-color-bg-black);
  border: 1px solid var(--mb-color-bg-dark);
}

.ThemeNight.EmbedFrame .enableDotsOnHover :global(.dc-tooltip circle.dot:hover),
.ThemeNight.EmbedFrame .enableDots :global(.dc-tooltip circle.dot) {
  fill: currentColor;
}

.ThemeTransparent.EmbedFrame {
  background-color: transparent;
}

.ThemeTransparent .EmbedFrameHeader,
.ThemeTransparent .EmbedFrameFooter {
  background-color: transparent;
}

.ThemeTransparent.EmbedFrame .DashCard .Card {
  background-color: transparent;
}
