.item {
  cursor: pointer;
  border-radius: 6px;
  word-break: break-all;

  &:hover {
    color: var(--mb-color-brand);
    background-color: var(--mb-color-brand-lighter);
  }

  &.selected {
    color: var(--mb-color-text-white);
    background-color: var(--mb-color-brand);
  }

  &.disabled {
    cursor: default;
    color: var(--mb-color-text-light);
  }
}
